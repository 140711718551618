import React, { Component } from 'react';

export default class LinearProgress extends Component {
  render() {
    return (
      <div className="linear-progress">
        <div className="bar bar1" />
        <div className="bar bar2" />
      </div>
    );
  }
}
