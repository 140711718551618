/*
eslint-disable jsx-a11y/anchor-is-valid
*/

import React, {Component} from 'react';

import {Link} from 'react-router-dom';

import PropTypes from 'prop-types';

import {injectIntl, FormattedMessage} from 'react-intl';

import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import AutoComplete from 'antd/lib/auto-complete';

import Tooltip from '../common/Tooltip';

import defaults from '../../constants/defaults';

import {getTrendingTags, lookupAccounts} from "../../backend/steem-client";

import appleSvg from '../../img/apple.svg';
import googleSvg from '../../img/google.svg';
import desktopSvg from '../../img/desktop.svg';


class Search extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      suggestions: [],
      tags: [],
      menuVisible: false
    }
  }

  timer = null;

  search = () => {
    const {search} = this.state;

    if (search.trim() === '') {
      return;
    }

    window.location.href = `https://search.esteem.app/search?q=${decodeURIComponent(search)}`;
  };

  onSelect = (val) => {
    let loc = '';
    if (val.indexOf('#') === 0) {
      loc = `/trending/${val.replace('#', '')}`;
    } else if (val.indexOf('@') === 0) {
      loc = `/${val}`;
    }

    if (loc) {
      const {history} = this.props;
      history.push(loc);
    }

    this.setState({search: val});
  };

  onChange = (val) => {
    this.setState({search: val});

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.loadSuggestions(val)
    }, 500)
  };

  loadSuggestions = (val) => {
    if (val.indexOf('#') === 0) {
      const tag = val.replace('#', '');

      if (tag.trim() === '') {
        return;
      }

      let fn = () => new Promise((resolve, reject) => {
        getTrendingTags('', 250).then(resp => {
          this.setState({tags: resp});
          resolve(resp);
        }).catch((e) => {
          reject(e);
        })
      });

      const {tags} = this.state;
      if (tags.length > 0) {
        fn = () => new Promise((resolve) => {
          resolve(tags)
        });
      }

      fn().then(resp => {
        const suggestions = resp.filter(x => x.name.toLowerCase().indexOf(tag.toLowerCase()) === 0).map(x => `#${x.name}`);
        this.setState({suggestions});
      });

    } else if (val.indexOf('@') === 0) {

      const username = val.replace('@', '');
      lookupAccounts(username, 5).then(r => {
        const suggestions = r.map(x => `@${x}`);
        this.setState({suggestions})
      })
    } else {
      this.setState({suggestions: []});
    }
  };

  render() {
    const {
      intl
    } = this.props;
    const {search, suggestions} = this.state;

    return <AutoComplete
      value={search}
      style={{width: '100%'}}
      onSelect={this.onSelect}
      onSearch={this.onChange}
      dataSource={suggestions}
      placeholder={intl.formatMessage({id: 'navbar.search-placeholder'})}
      onDropdownVisibleChange={(e) => {
        const {suggestions} = this.state;
        this.setState({menuVisible: (e && suggestions.length > 0)});
      }}
    >
      <Input
        onKeyDown={(e) => {
          const {menuVisible} = this.state;
          if (menuVisible) {
            return;
          }

          if (e.key === 'Enter') {

            this.search();
          }
        }}
        suffix={
          <Button
            className="search-btn"
            type="primary"
            onClick={this.search}
          >
            <FormattedMessage id="navbar.search"/>
          </Button>
        }
      />
    </AutoComplete>
  }
}

class NavBar extends Component {
  downloadsClicked = () => {
    const goDownloads = () => {
      const element = document.querySelector('div.downloads');
      if (element) element.scrollIntoView({behavior: 'smooth'});
    };

    setTimeout(() => {
      goDownloads();
    }, 500);
  };

  logoClicked = () => {
    const {location} = this.props;

    const newLoc = location.pathname === `/${defaults.filter}` ? `/` : `/${defaults.filter}`;

    if (newLoc === location.pathname) {
      return;
    }

    const {history} = this.props;
    history.push(newLoc);
  };

  changeTheme = () => {
    const {changeTheme} = this.props;

    changeTheme();
  };

  render() {
    const {
      intl
    } = this.props;

    return (
      <div className="nav-bar">
        <div className="nav-bar-inner">
          <a
            onClick={() => {
              this.logoClicked();
            }}
            className="logo"
            role="none"
            tabIndex="-1"
          ><span className="text-logo"/></a>
          <div className="search-bar">
            <Search {...this.props} />
          </div>
          <div className="alt-controls">
            <Tooltip
              title={intl.formatMessage({id: 'navbar.change-theme'})}
              placement="left"
              mouseEnterDelay={2}
            >
              <a
                className="switch-theme"
                onClick={() => {
                  this.changeTheme();
                }}
                role="none"
              >
                <i className="mi">brightness_medium</i>
              </a>
            </Tooltip>
          </div>
          <Link onClick={this.downloadsClicked} to="/about#downloads" className="download-menu-item">
            <span className="label">
              Downloads
            </span>
            <span className="icons">
             <img src={appleSvg} alt="IOS" className="img-apple"/>
            <img src={googleSvg} alt="Android" className="img-google"/>
            <img src={desktopSvg} alt="Desktop" className="img-desktop"/>
           </span>
          </Link>
        </div>
      </div>
    );
  }
}

NavBar.defaultProps = {};

NavBar.propTypes = {
  changeTheme: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  intl: PropTypes.instanceOf(Object).isRequired
};

export default injectIntl(NavBar);
