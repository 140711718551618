import React, {Component, Fragment} from 'react';

import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';

class DownloadTrigger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };
  }

  toggle = () => {
    const {modal} = this.state;

    this.setState({modal: !modal});
  };

  render() {
    const {children} = this.props;
    const {modal} = this.state;

    const clonedChildren = React.cloneElement(children, {
      onClick: this.toggle
    });

    return <Fragment>
      {clonedChildren}

      {modal &&
      <Modal
        visible
        footer={false}
        onCancel={() => {
          this.toggle();
        }}
        destroyOnClose
        centered
      >
        <div className="download-dialog-content">
          <h2 className="downloads-title">Download</h2>
          <div className="downloads-text">Enjoy eSteem for iPhone, iPad and Android, as well as PC, Mac or Linux
            devices:
          </div>
          <div className="download-buttons">
            <a className="download-button btn-desktop" target="_blank"
               href="https://github.com/eSteemApp/esteem-surfer/releases" rel="noopener noreferrer">DESKTOP</a>
            <a className="download-button btn-android" target="_blank"
               href="https://play.google.com/store/apps/details?id=app.esteem.mobile.android"
               rel="noopener noreferrer">ANDROID</a>
            <a className="download-button btn-ios" target="_blank"
               href="https://apps.apple.com/us/app/esteem-v2/id1451896376" rel="noopener noreferrer">IOS</a>
          </div>
        </div>
      </Modal>
      }
    </Fragment>;
  }
}

DownloadTrigger.propTypes = {
  children: PropTypes.element.isRequired
};

export default DownloadTrigger;
