/*
eslint-disable jsx-a11y/anchor-is-valid
*/

import React, {Fragment, Component} from 'react';
import {FormattedMessage} from 'react-intl';

import {Link} from 'react-router-dom';
import Menu from 'antd/lib/menu';

import {makeGroupKeyForEntries} from '../store/entries';
import filters from '../constants/filters.json';


import NavBar from './layout/NavBar'
import TagLink from './helpers/TagLink';
import ScrollReplace from './helpers/ScrollReplace';

import EntryListItem from './elements/EntryListItem';
import EntryListLoadingItem from './elements/EntryListLoadingItem';

import DropDown from './common/DropDown';
import ListSwitch from './elements/ListSwitch';
import LinearProgress from './common/LinearProgress';
import AppView from './elements/AppView';
import AdComponent from './common/AdComponent';


class EntryIndex extends Component {
  componentDidMount() {
    this.startFetch();
  }

  bottomReached = () => {
    const {global, entries} = this.props;
    const {selectedFilter, selectedTag} = global;

    const groupKey = makeGroupKeyForEntries(selectedFilter, selectedTag);
    const data = entries.get(groupKey);
    const loading = data.get('loading');
    const hasMore = data.get('hasMore');

    if (!loading && hasMore) {
      this.startFetch(true);
    }
  };

  componentDidUpdate(prevProps) {
    const {global: prevGlobal} = prevProps;
    const {global} = this.props;

    const prevPath = `${prevGlobal.selectedFilter}-${prevGlobal.selectedTag}`;
    const path = `${global.selectedFilter}-${global.selectedTag}`;

    if (!filters.includes(global.selectedFilter)) {
      return false;
    }

    if (prevPath !== path) {
      this.startFetch();
    }
  }

  startFetch = more => {
    const {global, fetchEntries, fetchTrendingTags, fetchPromotedEntries} = this.props;
    const {selectedFilter, selectedTag} = global;

    fetchEntries(selectedFilter, selectedTag, more);
    fetchTrendingTags();
    fetchPromotedEntries();

  };

  makeFilterMenu = active => {
    const {global} = this.props;
    const {selectedTag} = global;

    return (
      <Menu selectedKeys={[active]} className="surfer-dropdown-menu">
        {filters.map(filter => {
          const linkTo = selectedTag ? `/${filter}/${selectedTag}` : `/${filter}`;
          return (
            <Menu.Item key={filter}>
              <Link to={linkTo}>
                <FormattedMessage id={`entry-index.filter-${filter}`}/>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {

    const {entries, promotedEntries, trendingTags, location, global} = this.props;
    const {selectedFilter, selectedTag} = global;
    const promoted = promotedEntries.get('entries').toArray();
    const groupKey = makeGroupKeyForEntries(selectedFilter, selectedTag);

    const data = entries.get(groupKey);

    if (data === undefined) {
      return null;
    }

    const filterMenu = this.makeFilterMenu(selectedFilter);

    const entryList = data.get('entries');
    const loading = data.get('loading');

    return (
      <div className="wrapper">
        <AppView />
        <NavBar{...this.props} />
        <div className="app-content entry-index">
          <div className="page-header">
            <div className="left-side">
              <h2 className="tag-list-header">
                <FormattedMessage id="entry-index.tags"/>
              </h2>
            </div>
            <div className="right-side">
              <div className={`page-tools ${loading ? 'loading' : ''}`}>
                <div className="filter-select">
                  <span className="label">
                    <FormattedMessage
                      id={`entry-index.filter-${selectedFilter}`}
                    />
                  </span>
                  <DropDown menu={filterMenu} location={location}/>
                </div>
                <ListSwitch {...this.props} />
              </div>
              {loading && entryList.size === 0 ? <LinearProgress/> : ''}
            </div>
          </div>
          <div className="page-inner" id="app-content">
            <div className="left-side">
              <div className="tag-list">
                {trendingTags.list.map(t => {
                  const cls = `tag-list-item ${
                    selectedTag === t ? 'selected-item' : ''
                    }`;
                  return (
                    <TagLink {...this.props} tag={t} key={t}>
                      <a className={cls}>{t}</a>
                    </TagLink>
                  );
                })}
              </div>
            </div>
            <div className="right-side">
              <div className={`entry-list ${loading ? 'loading' : ''}`}>
                <div
                  className={`entry-list-body ${
                    global.listStyle === 'grid' ? 'grid-view' : ''
                    }`}
                >
                  {loading && entryList.size === 0 ? (
                    <EntryListLoadingItem/>
                  ) : (
                    ''
                  )}
                  {entryList.valueSeq().map((d, i) => {
                    const e = [];
                    if (i % 3 === 0) {
                      const ix = i / 3 - 1;
                      if (promoted[ix] !== undefined) {
                        const p = promoted[ix];

                        e.push(
                          <>
                          <AdComponent/>
                          <EntryListItem
                            key={`${p.author}-${p.permlink}-prom`}
                            {...Object.assign({}, this.props, { entry: p })}
                            promoted
                          />
                          </>
                        );
                      }
                    }

                    e.push(
                      <EntryListItem
                        key={`${d.author}-${d.permlink}`}
                        {...Object.assign({}, this.props, { entry: d })}
                      />
                    );

                    return (
                      <Fragment key={`${d.author}-${d.permlink}`}>
                        {[...e]}
                      </Fragment>
                    );

                  })}
                  <ScrollReplace
                    {...this.props}
                    onBottom={this.bottomReached}
                  />
                </div>
              </div>
              {loading && entryList.size > 0 ? <LinearProgress/> : ''}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EntryIndex