/*
eslint-disable jsx-a11y/anchor-is-valid
*/

import React, {Component} from 'react';

import PropTypes from 'prop-types';

import {injectIntl} from 'react-intl';

import Tooltip from '../common/Tooltip';

import DownloadTrigger from '../helpers/DownloadTrigger';

class EntryReblogBtn extends Component {
  render() {
    const {intl} = this.props;

    return (
      <div className="reblog-btn">
        <DownloadTrigger>
          <a className="inner-btn" role="none">
            <Tooltip
              title={intl.formatMessage({id: 'entry-reblog.reblog'})}
              mouseEnterDelay={2}
            >
              <i className="mi">repeat</i>
            </Tooltip>
          </a>
        </DownloadTrigger>
      </div>
    );
  }
}

EntryReblogBtn.defaultProps = {};

EntryReblogBtn.propTypes = {
  intl: PropTypes.instanceOf(Object).isRequired
};

export default injectIntl(EntryReblogBtn);
