/*
eslint-disable jsx-a11y/anchor-is-valid
*/

import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

import Select from 'antd/lib/select';
import message from 'antd/lib/message';

import {
  FormattedRelative,
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl
} from 'react-intl';

import {
  getState,
  getContent
} from '../backend/steem-client';

import {renderPostBody, catchPostImage} from '@esteemapp/esteem-render-helpers';

import NotFound from './404';

import NavBar from './layout/NavBar';

import UserAvatar from './elements/UserAvatar';
import FormattedCurrency from './elements/FormattedCurrency';
import EntryPayout from './elements/EntryPayout';
import EntryVotes from './elements/EntryVotes';
import EntryVoteBtn from './elements/EntryVoteBtn';
import EntryReblogBtn from './elements/EntryReblogBtn';

import LinearProgress from './common/LinearProgress';

import EntryLink, {makePath as makePathEntry} from './helpers/EntryLink';
import TagLink, {makePath as makePathTag} from './helpers/TagLink';
import AccountLink, {makePath as accountMakePath} from './helpers/AccountLink';
import DownloadTrigger from './helpers/DownloadTrigger';
import AppView from './elements/AppView';

import authorReputation from '../utils/author-reputation';
import parseDate from '../utils/parse-date';
import parseToken from '../utils/parse-token';
import appName from '../utils/app-name';
import sumTotal from '../utils/sum-total';
import dmca from '../constants/dmca';

import {
  reddit as redditIcon,
  twitter as twitterIcon,
  facebook as facebookIcon
} from './svg';

import {
  makeShareUrlReddit,
  makeShareUrlTwitter,
  makeShareUrlFacebook
} from '../utils/url-share';
import AdComponent from './common/AdComponent';

const tooltipStyle = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
};

class ReplyListItem extends PureComponent {
  render() {
    const {depth, intl, reply} = this.props;

    const reputation = authorReputation(reply.author_reputation);
    const created = parseDate(reply.created);
    const renderedBody = {__html: renderPostBody(reply)};
    const isPayoutDeclined = parseToken(reply.max_accepted_payout) === 0;
    const totalPayout = sumTotal(reply);
    const voteCount = reply.active_votes.length;

    const toolTipDate = intl.formatDate(parseDate(reply.created), tooltipStyle);

    return (
      <div className="reply-list-item">
        <div className="item-inner">
          <div className="item-header">
            <AccountLink {...this.props} username={reply.author}>
              <div className="author-part">
                <div className="author-avatar">
                  <UserAvatar user={reply.author} size="medium"/>
                </div>
                <div className="author">
                  <span className="author-name">{reply.author}</span>
                  <span className="author-reputation">{reputation}</span>
                </div>
              </div>
            </AccountLink>
            <span className="separator"/>
            <EntryLink
              {...this.props}
              author={reply.author}
              permlink={reply.permlink}
            >
              <span className="date" title={toolTipDate}>
                <FormattedRelative updateInterval={0} value={created} initialNow={Date.now()}/>
              </span>
            </EntryLink>       
          </div>
          <div className="item-body markdown-view mini-markdown" dangerouslySetInnerHTML={renderedBody}/>
          <div className="item-controls">
            <div className="voting">
              <EntryVoteBtn {...this.props} />
            </div>
            <EntryPayout {...this.props} entry={reply}>
              <a className={`total-payout ${isPayoutDeclined ? 'payout-declined' : ''}`}>
                <FormattedCurrency {...this.props} value={totalPayout}/>
              </a>
            </EntryPayout>
            <span className="separator"/>
            <EntryVotes {...this.props} entry={reply}>
              <a className="voters">
                <i className="mi">people</i>
                {voteCount}
              </a>
            </EntryVotes>
            <span className="separator"/>
            <DownloadTrigger>
            <span
              className="reply-btn"
              role="none"
            >
              <FormattedMessage id="entry.reply"/>
            </span>
            </DownloadTrigger>
          </div>
        </div>
        {(() => {
          if (reply.replies && reply.replies.length > 0) {
            if (depth >= 8) {
              return (
                <div className="item-show-more">
                  <EntryLink
                    {...this.props}
                    author={reply.author}
                    permlink={reply.permlink}
                  >
                    <a>
                      <FormattedMessage id="entry.more-replies"/>
                    </a>
                  </EntryLink>
                </div>
              );
            }
            return (<ReplyList {...this.props} replies={reply.replies} depth={depth + 1}/>)
          }
        })()}
      </div>
    );
  }
}

ReplyListItem.defaultProps = {};

ReplyListItem.propTypes = {
  depth: PropTypes.number.isRequired,
  reply: PropTypes.instanceOf(Object).isRequired,
  intl: PropTypes.instanceOf(Object).isRequired
};


class ReplyList extends PureComponent {
  render() {
    const {replies, depth} = this.props;

    return (
      <div className="entry-reply-list">
        {replies.map(reply => (
          <ReplyListItem
            {...this.props}
            reply={reply}
            key={`${reply.author}-${reply.permlink}`}
            depth={depth}
          />
        ))}
      </div>
    );
  }
}

ReplyList.defaultProps = {};

ReplyList.propTypes = {
  depth: PropTypes.number.isRequired,
  replies: PropTypes.arrayOf(Object).isRequired
};


class Entry extends PureComponent {
  constructor(props) {
    super(props);

    const {visitingEntry} = this.props;

    this.state = {
      entry: visitingEntry || null,
      notFound: false,
      replies: [],
      repliesLoading: true,
      replySort: 'trending'
    };
  }

  componentDidMount() {
    this.doFetch();

    window.addEventListener('md-author-clicked', this.mdAuthorClicked);
    window.addEventListener('md-post-clicked', this.mdEntryClicked);
    window.addEventListener('md-tag-clicked', this.mdTagClicked);
  }

  componentWillUnmount() {
    window.removeEventListener('md-author-clicked', this.mdAuthorClicked);
    window.removeEventListener('md-post-clicked', this.mdEntryClicked);
    window.removeEventListener('md-tag-clicked', this.mdTagClicked);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.doFetch();
    }
  }

  mdAuthorClicked = async e => {
    const {author} = e.detail;
    const {history} = this.props;
    const newLoc = accountMakePath(author);
    history.push(newLoc);
  };

  mdEntryClicked = e => {
    const {history} = this.props;
    const {category, author, permlink} = e.detail;

    const newLoc = makePathEntry(category, author, permlink);

    history.push(newLoc);
  };

  mdTagClicked = e => {
    const {history, global} = this.props;
    const {tag} = e.detail;
    const {selectedFilter} = global;

    const newLoc = makePathTag(selectedFilter, tag);

    history.push(newLoc);
  };

  compileReplies = (parent, sortOrder) => {
    const {match} = this.props;
    const {replyId} = match.params;

    const allPayout = c =>
      parseFloat(c.pending_payout_value.split(' ')[0]) +
      parseFloat(c.total_payout_value.split(' ')[0]) +
      parseFloat(c.curator_payout_value.split(' ')[0]);

    const absNegative = a => a.net_rshares < 0;

    const sortOrders = {
      trending: (a, b) => {
        if (absNegative(a)) {
          return 1;
        }

        if (absNegative(b)) {
          return -1;
        }

        const apayout = allPayout(a);
        const bpayout = allPayout(b);
        if (apayout !== bpayout) {
          return bpayout - apayout;
        }

        return 0;
      },
      author_reputation: (a, b) => {
        const keyA = authorReputation(a.author_reputation);
        const keyB = authorReputation(b.author_reputation);

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        return 0;
      },
      votes: (a, b) => {
        const keyA = a.net_votes;
        const keyB = b.net_votes;

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        return 0;
      },
      created: (a, b) => {
        if (absNegative(a)) {
          return 1;
        }

        if (absNegative(b)) {
          return -1;
        }

        const keyA = Date.parse(a.created);
        const keyB = Date.parse(b.created);

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        return 0;
      }
    };

    // It might be undefined if newly created.
    if (!parent.replies) {
      return [];
    }

    const replies = [];

    parent.replies.forEach(k => {
      const reply = this.stateData.content[k];

      replies.push(
        Object.assign(
          {},
          reply,
          {replies: this.compileReplies(reply, sortOrder)},
          {author_data: this.stateData.accounts[reply.author]},
          {_selected_: reply.id === replyId}
        )
      );
    });

    replies.sort(sortOrders[sortOrder]);

    return replies;
  };

  doFetch = () => {
    const {match} = this.props;
    const {category, permlink} = match.params;
    let {username} = match.params;

    username = username.replace('@', '');

    this.statePath = `/${category}/@${username}/${permlink}`;
    this.entryPath = `${username}/${permlink}`;
    this.stateData = null;

    this.fetch().then(() => {
      const {location} = this.props;

      const {hash} = location;
      if (hash === '#replies') {
        this.scrollToReplies();
      }
    });
  };

  fetch = async () => {
    const {intl} = this.props;

    this.setState({replies: [], repliesLoading: true, replySort: 'trending'});

    const {match, setVisitingEntry} = this.props;
    const {permlink} = match.params;
    let {username} = match.params;

    username = username.replace('@', '');

    const entry = await getContent(username, permlink);
    if (dmca.includes(`${username}/${permlink}`)) {
      entry.body = "This post is not available due to a copyright claim.";
    }
    if (!entry || entry.id === 0) {
      this.setState({notFound: true});
      return;
    }

    setVisitingEntry(entry);

    this.setState({entry});

    try {
      this.stateData = await getState(this.statePath);
    } catch (err) {
      this.stateData = null;
      message.error(intl.formatMessage({id: 'entry.fetch-error'}));
    }

    if (this.stateData) {
      const theEntry = this.stateData.content[this.entryPath];
      const replies = this.compileReplies(theEntry, 'trending');
      this.setState({replies});
    }

    this.setState({repliesLoading: false});
  };

  replySortOrderChanged = value => {
    this.setState({replySort: value});

    const theEntry = this.stateData.content[this.entryPath];
    const replies = this.compileReplies(theEntry, value);
    this.setState({replies});
  };

  scrollToReplies = () => {
    const replyWrapper = document.querySelector('.entry-replies');
    const scrollEl = document.querySelector('#app-content');

    if (!replyWrapper || !scrollEl) {
      return;
    }

    scrollEl.scrollTop = replyWrapper.offsetTop;
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  shareReddit = () => {
    const { entry } = this.state;
    const u = makeShareUrlReddit(
      entry.category,
      entry.author,
      entry.permlink,
      entry.title
    );
    window.open(u,"_blank");
  };

  shareTwitter = () => {
    const { entry } = this.state;
    const u = makeShareUrlTwitter(
      entry.category,
      entry.author,
      entry.permlink,
      entry.title
    );
    window.open(u,"_blank");
  };

  shareFacebook = () => {
    const { entry } = this.state;
    const u = makeShareUrlFacebook(
      entry.category,
      entry.author,
      entry.permlink
    );
    window.open(u,"_blank");
  };

  render() {
    const {
      entry,
      notFound,
      repliesLoading
    } = this.state;
    const {intl} = this.props;

    if (notFound) {
      return (
        <div className="wrapper">
          <NavBar{...this.props} />
          <NotFound {...this.props} />
        </div>
      )
    }

    let content = null;

    if (entry) {
      const {children} = entry;

      const {replies, replySort} = this.state;

      const reputation = authorReputation(entry.author_reputation);
      const created = parseDate(entry.created);
      const last_update = parseDate(entry.last_update);

      const renderedBody = {__html: renderPostBody(entry)};
      const image = catchPostImage(entry.body);

      let jsonMeta;
      try {
        jsonMeta = JSON.parse(entry.json_metadata);
      } catch (e) {
        jsonMeta = {};
      }

      const tags = [...new Set(jsonMeta.tags)]; // Sometimes tag list comes with duplicate items
      const app = appName(jsonMeta.app);
      const orgName = this.capitalize(app.split('/')[0]) || "Esteem";
      const totalPayout = sumTotal(entry);
      const isPayoutDeclined = parseToken(entry.max_accepted_payout) === 0;
      const voteCount = entry.active_votes.length;

      const isComment = entry.parent_author.trim().length > 0;

      const hideParentLink = !entry.parent_permlink.startsWith('re-');

      const rootUrl = entry.url.split('#')[0];
      const [, , rootAuthor, rootPermlink] = rootUrl.split('/');

      const toolTipDate = intl.formatDate(parseDate(entry.created), tooltipStyle);

      content = (
        <>
          <AdComponent/>
          <div className="the-entry">
            <span itemScope itemType="http://schema.org/Article">
              <div className="entry-header">
                {isComment && (
                  <div className="comment-entry-header">
                    <div className="comment-entry-header-title">
                      {' '}
                      RE: {entry.root_title}
                    </div>
                    <div className="comment-entry-header-info">
                      <FormattedMessage id="entry.comment-entry-title"/>
                    </div>
                    <p className="comment-entry-root-title">
                      {' '}
                      {entry.root_title}
                    </p>
                    <ul className="comment-entry-opts">
                      <li>
                        <EntryLink
                          {...this.props}
                          author={rootAuthor.replace('@', '')}
                          permlink={rootPermlink}>
                          <a>
                            <FormattedMessage id="entry.comment-entry-go-root"/>
                          </a>
                        </EntryLink>
                      </li>
                      {!hideParentLink && (
                        <li>
                          <EntryLink
                            {...this.props}
                            author={entry.parent_author}
                            permlink={entry.parent_permlink}>
                            <a>
                              <FormattedMessage id="entry.comment-entry-go-parent"/>
                            </a>
                          </EntryLink>
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                <h1 className="entry-title">
                  <span itemProp="headline name">
                    {entry.title}
                  </span>
                </h1>
                <meta itemProp="image" content={image}/>
                <div className="entry-info">
                  <AccountLink {...this.props} username={entry.author}>
                    <div className="author-part">
                      <div className="author-avatar">
                        <UserAvatar user={entry.author} size="medium"/>
                      </div>
                      <div className="author">
                        <span className="author-name">
                          <span itemProp="author" itemScope itemType="http://schema.org/Person">
                            <span itemProp="name">
                              {entry.author}
                            </span>
                          </span>
                        </span>
                        <span className="author-reputation">{reputation}</span>
                      </div>
                    </div>
                  </AccountLink>
                  <TagLink {...this.props} tag={entry.category}>
                    <a className="category">
                      <span itemProp="articleSection">
                        {entry.category}
                      </span>
                    </a>
                  </TagLink>
                  <span className="separator"/>
                  <span className="date" title={toolTipDate}>{intl.formatRelative(created)}</span>
                  <meta itemProp="datePublished" content={created.toISOString()}/>
                  <meta itemProp="dateModified" content={last_update.toISOString()}/>
                  <meta itemProp="mainEntityOfPage" itemScope itemType="http://schema.org/WebPage" itemID={`https://esteem.app${entry.url}`}/>
                </div>
              </div>
              <div itemProp="articleBody" className="entry-body markdown-view user-selectable"
                   dangerouslySetInnerHTML={renderedBody}/>
              <div className={`entry-footer ${repliesLoading ? 'loading' : ''}`}>
                <div className="entry-tags">
                  {tags.map(t => (
                    <TagLink {...this.props} tag={t} key={t}>
                      <div className="entry-tag">{t}</div>
                    </TagLink>
                  ))}
                </div>
                <div className="entry-info">
                  <div className="left-side">
                    <div className="date" title={toolTipDate}>
                      <i className="mi">access_time</i>
                      {intl.formatRelative(created)}
                    </div>
                    <span className="separator"/>
                    <AccountLink {...this.props} username={entry.author}>
                      <div className="author">
                        <span className="author-name">{entry.author}</span>
                        <span className="author-reputation">{reputation}</span>
                      </div>
                    </AccountLink>
                    <span itemProp="publisher" itemScope itemType="http://schema.org/Organization">
                      <meta itemProp="name" content={orgName}/>
                      <span itemProp="logo" itemScope="" itemType="http://schema.org/ImageObject">
                        <meta itemProp="url" content="https://esteem.app/icon.png"/>
                      </span>
                    </span>
                    {app && (
                      <>
                        <span className="separator"/>
                        <div className="app">
                            <FormattedHTMLMessage
                              id="entry.via-app"
                              values={{app}}
                            />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="right-side">
                    <DownloadTrigger>
                      <span
                        className="reply-btn"
                        role="none"
                      >
                      <FormattedMessage id="entry.reply"/>
                    </span>
                    </DownloadTrigger>
                  </div>
                </div>
                <div className="entry-controls">
                  <div className="voting">
                    <EntryVoteBtn {...this.props} />
                  </div>
                  <EntryPayout {...this.props} entry={entry}>
                    <a className={`total-payout ${
                      isPayoutDeclined ? 'payout-declined' : ''
                    }`}>
                      <FormattedCurrency {...this.props} value={totalPayout}/>
                    </a>
                  </EntryPayout>
                  <EntryVotes {...this.props} entry={entry}>
                    <a className="voters">
                      <i className="mi">people</i>
                      {voteCount}
                    </a>
                  </EntryVotes>

                  <EntryReblogBtn {...this.props} />
                  <div className="sub-menu">
                      <a className="sub-menu-item" onClick={this.shareReddit} role="none">
                        {redditIcon}
                      </a>
                      <a
                        className="sub-menu-item"
                        onClick={this.shareTwitter}
                        role="none"
                      >
                        {twitterIcon}
                      </a>
                      <a
                        className="sub-menu-item"
                        onClick={this.shareFacebook}
                        role="none"
                      >
                        {facebookIcon}
                      </a>
                    </div>
                </div>
              </div>
            </span>
            <AdComponent/>
            {repliesLoading && <LinearProgress/>}
            <div className="clearfix"/>
            <div className="entry-replies">
              <div className="entry-replies-header">
                <div className="reply-count">
                  <i className="mi">comment</i>
                  <FormattedMessage
                    id="entry.n-replies"
                    values={{n: children}}
                  />
                </div>
                {replies.length > 0 && (
                  <div className="sort-order">
                                        <span className="label">
                                          <FormattedMessage id="entry.reply-sort-order"/>
                                        </span>
                    <Select
                      defaultValue="trending"
                      size="small"
                      style={{width: '120px'}}
                      value={replySort}
                      onChange={this.replySortOrderChanged}>
                      <Select.Option value="trending">
                        <FormattedMessage id="entry.reply-sort-order-trending"/>
                      </Select.Option>
                      <Select.Option value="author_reputation">
                        <FormattedMessage id="entry.reply-sort-order-reputation"/>
                      </Select.Option>
                      <Select.Option value="votes">
                        <FormattedMessage id="entry.reply-sort-order-votes"/>
                      </Select.Option>
                      <Select.Option value="created">
                        <FormattedMessage id="entry.reply-sort-order-created"/>
                      </Select.Option>
                    </Select>
                  </div>
                )}
              </div>
              <div className="entry-replies-body">
                <ReplyList {...this.props} replies={replies} depth={1}/>
              </div>
            </div>
          </div>
        </>
      )
    }

    return (
      <div className="wrapper">
        <AppView />
        <NavBar{...this.props} />
        <div className="app-content entry-page">
          {content}
        </div>
      </div>
    )
  }
}


export default injectIntl(Entry);