import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

// i18n
import {addLocaleData, IntlProvider} from 'react-intl';

import en from 'react-intl/locale-data/en';
import tr from 'react-intl/locale-data/tr';
import ru from 'react-intl/locale-data/ru';
import ko from 'react-intl/locale-data/ko';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import pl from 'react-intl/locale-data/pl';
import hu from 'react-intl/locale-data/hu';
import nl from 'react-intl/locale-data/nl';
import de from 'react-intl/locale-data/de';

import {flattenMessages} from '../utils';

import messages from '../locales';

import {fetchDynamicProps} from '../store/dynamic-props';

addLocaleData([
  ...en,
  ...tr,
  ...ru,
  ...ko,
  ...es,
  ...pt,
  ...pl,
  ...hu,
  ...nl,
  ...de
]);

class App extends React.Component {

  componentDidMount() {
    // Refresh global dynamic props
    this.refreshGlobalProps();
    this.dynamicPropsInt = setInterval(this.refreshGlobalProps, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.dynamicPropsInt);
  }

  refreshGlobalProps = () => {
    const {fetchDynamicProps} = this.props;

    fetchDynamicProps();
  };

  render() {
    const {children, global} = this.props;
    const {locale,} = global;

    return (
      <IntlProvider
        locale={locale}
        messages={flattenMessages(messages[locale])}
      >
        {children}
      </IntlProvider>
    );
  }
}

App.defaultProps = {};

App.propTypes = {
  children: PropTypes.element.isRequired,
  global: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    global: state.global
  };
}


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDynamicProps
    },
    dispatch
  );


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
