import React, {Component} from 'react';

import {bindActionCreators} from 'redux';

import connect from 'react-redux/es/connect/connect';

import AccountPage from '../components/Account';

import {fetchEntries} from '../store/entries';

import {
  changeListStyle,
  changeTheme
} from '../store/global';

import {setVisitingAccount} from '../store/visiting-account';
import {setVisitingEntry} from '../store/visiting-entry';

class AccountContainer extends Component {
  render() {
    return <AccountPage {...this.props} />;
  }
}

const mapStateToProps = ({global, trendingTags, entries, dynamicProps}) => ({
  global,
  trendingTags,
  entries,
  dynamicProps
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEntries,
      changeListStyle,
      changeTheme,
      setVisitingAccount,
      setVisitingEntry
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountContainer)