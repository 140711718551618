import React, {Component} from 'react';

import {bindActionCreators} from 'redux';

import connect from 'react-redux/es/connect/connect';

import {
  Index as IndexPage,
  About as AboutPage,
  Signup as SignupPage,
  WhitePaper as WhitePaperPage,
  Privacy as PrivacyPage,
  Tos as TosPage,
  GuestPosts as GuestPostsPage,
  Contribute as ContributePage,
} from '../components/Index';


class IndexContainerCls extends Component {
  render() {
    return <IndexPage {...this.props} />;
  }
}


class AboutContainerCls extends Component {
  render() {
    return <AboutPage {...this.props} />;
  }
}

class SignupContainerCls extends Component {
  render() {
    return <SignupPage {...this.props} />;
  }
}

class WhitePaperContainerCls extends Component {
  render() {
    return <WhitePaperPage {...this.props} />;
  }
}

class PrivacyContainerCls extends Component {
  render() {
    return <PrivacyPage {...this.props} />;
  }
}

class TosContainerCls extends Component {
  render() {
    return <TosPage {...this.props} />;
  }
}

class GuestPostsContainerCls extends Component {
  render() {
    return <GuestPostsPage {...this.props} />;
  }
}

class ContributeContainerCls extends Component {
  render() {
    return <ContributePage {...this.props} />;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  );


const IndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexContainerCls);

const AboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutContainerCls);

const SignupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupContainerCls);

const WhitePaperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WhitePaperContainerCls);

const PrivacyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyContainerCls);

const TosContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TosContainerCls);

const GuestPostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestPostsContainerCls);

const ContributeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContributeContainerCls);

export {IndexContainer, AboutContainer, WhitePaperContainer, PrivacyContainer, TosContainer, SignupContainer, GuestPostsContainer, ContributeContainer};