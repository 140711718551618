import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Input,
  message,
} from 'antd';

import { FormattedMessage, injectIntl } from 'react-intl';

import logo from '../../img/logo-big.png';
import defaults from '../../constants/defaults';

import {
  signUp as signUpFn
} from '../../backend/steem-client';

import DownloadTrigger from '../helpers/DownloadTrigger';


class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      username: '',
      email: '',
      refCode: new URLSearchParams(props.location.search).get('referral') || '',
      disabled: new URLSearchParams(props.location.search).get('referral')?true:false,
      done: false
    };
  }
  
  doSignUp = () => {
    const { username, email, refCode } = this.state;
    this.setState({ processing: true });

    return signUpFn(username, email, refCode)
      .then(resp => {
        if (resp && resp.data && resp.data.code) {
          this.setState({ processing: false });
          message.error(resp.data.message);
        } else {
          this.setState({ done: true });
          return resp;
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          this.setState({ processing: false });
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  usernameChanged = e => {
    this.setState({ username: e.target.value.toString().toLowerCase() });
  };

  emailChanged = e => {
    this.setState({ email: e.target.value });
  };

  refCodeChanged = e => {
    this.setState({ disabled: false, refCode: e.target.value });
  };

  login = () => {
    //const { backFn } = this.props;
    //backFn();
    window.open('esteem://login', "_blank");
  };
  
  home = () => {
    const {location} = this.props;

    const newLoc = location.pathname === `/${defaults.filter}` ? `/` : `/${defaults.filter}`;

    if (newLoc === location.pathname) {
      return;
    }

    const {history} = this.props;
    history.push(newLoc);
  };
  
  render() {
    const { intl } = this.props;
    const { processing, username, email, refCode, done, disabled } = this.state;
    
    return (
      <div className="sign-up-dialog-content">
        <div className="dialog-header">
          <div className="logo">
            <a href='#!' onClick={this.home}>
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="dialog-header-text">
            <FormattedMessage id="sign-up.title" />
          </div>
          <div className="dialog-description-text">
            <FormattedMessage id="sign-up.description-1" />
            &nbsp;
            <DownloadTrigger>
              <a href='#!' role="none">
                <FormattedMessage id="sign-up.description-2" />
              </a>
            </DownloadTrigger>
            &nbsp;
            <FormattedMessage id="sign-up.description-3" />
          </div>
        </div>
        <div className="dialog-form">
          {(() => {
            if (done) {
              return (
                <div className="sign-up-success">
                  <p>
                    <i className="mi">check</i>
                    <FormattedMessage
                      id="sign-up.success-message-1"
                      values={{ email }}
                    />
                  </p>
                  <p>
                    <FormattedMessage id="sign-up.success-message-2" />
                  </p>
                  <p>
                    <Button
                      size="large"
                      htmlType="button"
                      type="primary"
                      block
                      onClick={this.login}
                    >
                      <FormattedMessage id="sign-up.login-btn-label" />
                    </Button>
                  </p>
                </div>
              );
            }

            return (
              <Fragment>
                <p>
                  <Input
                    type="text"
                    autoFocus
                    value={username}
                    onChange={this.usernameChanged}
                    placeholder={intl.formatMessage({
                      id: 'sign-up.username-placeholder'
                    })}
                  />
                </p>
                <p>
                  <Input
                    type="email"
                    value={email}
                    onChange={this.emailChanged}
                    placeholder={intl.formatMessage({
                      id: 'sign-up.email-placeholder'
                    })}
                  />
                </p>
                <p>
                  <Input
                    type="text"
                    value={refCode}
                    disabled={disabled}
                    onChange={this.refCodeChanged}
                    placeholder={intl.formatMessage({
                      id: 'sign-up.ref-code'
                    })}
                  />
                </p>
                <p>
                  <Button
                    size="large"
                    htmlType="button"
                    type="primary"
                    block
                    disabled={processing}
                    onClick={this.doSignUp}
                  >
                    <FormattedMessage id="sign-up.sign-up" />
                  </Button>
                </p>
                <p>
                  <FormattedMessage id="sign-up.login-1" />
                  &nbsp;
                  <a href='#!' onClick={this.login}>
                    <FormattedMessage id="sign-up.login-2" />
                  </a>
                </p>
              </Fragment>
            );
          })()}
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  intl: PropTypes.instanceOf(Object).isRequired,
  //backFn: PropTypes.func.isRequired
};

export default injectIntl(SignUp);
