import {Map, OrderedMap} from 'immutable';
import {LOCATION_CHANGE} from 'react-router-redux';

import {getContent, getPromotedPosts} from '../backend/steem-client';

import {UPDATE_ENTRY} from './entries';

export const FETCH_BEGIN = 'promoted-entries/FETCH_BEGIN';
export const FETCH_OK = 'promoted-entries/FETCH_OK';
export const FETCH_ERROR = 'promoted-entries/FETCH_ERROR';

const defaultState = Map({
  entries: OrderedMap({}),
  loading: false
});

/* Reducers */

export default function promotedEntries(state = defaultState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return state.set('entries', OrderedMap({})).set('loading', false);
    }
    case FETCH_BEGIN: {
      return state.set('loading', true);
    }
    case FETCH_OK: {
      const {data: newEntries} = action.payload;

      let newState = state.set('loading', false).set('entries', OrderedMap({}));

      newEntries.forEach(entry => {
        newState = newState.setIn(
          ['entries', `${entry.author}-${entry.permlink}`],
          entry
        );
      });

      return newState;
    }
    case FETCH_ERROR: {
      return state.set('loading', false);
    }
    case UPDATE_ENTRY: {
      const {data} = action.payload;
      let newState = state.asImmutable();

      if (newState.hasIn(['entries', `${data.author}-${data.permlink}`])) {
        newState = newState.setIn(
          ['entries', `${data.author}-${data.permlink}`],
          data
        );
      }

      return newState;
    }
    default:
      return state;
  }
}

/* Actions */

export function fetchPromotedEntries() {
  return dispatch => {
    dispatch({
      type: FETCH_BEGIN
    });

    getPromotedPosts()
      .then(resp => {
        const prms = resp.map(x => getContent(x.author, x.permlink));
        return Promise.all(prms);
      })
      .then(resp => {
        dispatch({
          type: FETCH_OK,
          payload: {
            data: resp
          }
        });

        return resp;
      })
      .catch(() => {
        dispatch({
          type: FETCH_ERROR
        });
      });
  };
}
