export const SET = 'visiting-entry/SET';

const defaultState = null;

export default function visitingEntry(state = defaultState, action) {
    switch (action.type) {
        case SET: {
            const {entry} = action.payload;

            return Object.assign({}, entry);
        }
        default:
            return state;
    }
}

export const setVisitingEntry = entry => dispatch => {
    dispatch({
        type: SET,
        payload: {entry}
    });
};