import {Client} from '@hiveio/dhive';
const DEFAULT_SERVER = [
  'https://api.hive.blog',
  'https://rpc.ecency.com',
];
let client = new Client(DEFAULT_SERVER, {
  timeout: 4000,
  failoverThreshold: 10,
  consoleOnFailover: true
});

export const votingPower = account => {
  const calc = client.rc.calculateVPMana(account);
  const { percentage } = calc;
  return percentage / 100;
};
