import {Client} from '@hiveio/dhive';
import axios from 'axios';

const DEFAULT_SERVER = [
  'https://api.hive.blog',
  'https://rpc.ecency.com'
];
let client = new Client(DEFAULT_SERVER, {
  timeout: 4000,
  failoverThreshold: 10,
  consoleOnFailover: true
});

export const setAddress = address => {
  client = new Client(address);
};

export const getDiscussions = (what, query) =>
  client.database.getDiscussions(what, query);


export const getTrendingTags = (afterTag = '', limit = 50) => client.database
  .call('get_trending_tags', [afterTag, limit]);


export const lookupAccounts = (q, limit = 50) => client.database.call('lookup_accounts', [q, limit]);

export const getRepliesByLastUpdate = query =>
  client.database.call('get_replies_by_last_update', [
    query.start_author,
    query.start_permlink,
    query.limit
  ]);


export const getContent = (username, permlink) =>
  client.call('condenser_api', 'get_content', [username, permlink]);


export const getAccounts = usernames => client.database.getAccounts(usernames);

export const getAccount = username =>
  client.database.getAccounts([username]).then(resp => resp[0]);

export const getPromotedPosts = () =>
  axios
    .get(`/esteem_promoted`)
    .then(resp => {
      return resp.data;
    });

export const getDelgateeVestingShares = user =>
  axios
    .get(`/delegatee_vesting_shares/${user}`)
    .then(resp => resp.data);

export const signUp = (username, email, referral) =>
  axios
    .post(`/account-create`, {
      username: username,
      email: email,
      referral: referral
    })
    .then(resp => {
       return resp;
    });

export const getState = path => client.database.getState(path);

export const getActiveVotes = (author, permlink) =>
  client.database.call('get_active_votes', [author, permlink]);

export const getFollowCount = username =>
  client.database.call('get_follow_count', [username]);

export const getFollowing = (
  follower,
  startFollowing,
  followType = 'blog',
  limit = 100
) =>
  client.database.call('get_following', [
    follower,
    startFollowing,
    followType,
    limit
  ]);

export const getFollowers = (
  following,
  startFollowing,
  followType = 'blog',
  limit = 100
) =>
  client.database.call('get_followers', [
    following,
    startFollowing,
    followType,
    limit
  ]);

export const getDynamicGlobalProperties = () =>
  client.database.getDynamicGlobalProperties();

export const getFeedHistory = () => client.database.call('get_feed_history');

export const getRewardFund = () =>
  client.database.call('get_reward_fund', ['post']);

export const getVestingDelegations = (account, from = '', limit = 50) =>
  client.database.call('get_vesting_delegations', [account, from, limit]);
