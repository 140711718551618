/*
eslint-disable jsx-a11y/anchor-is-valid
*/

import React, {Component} from 'react';

import DownloadTrigger from '../helpers/DownloadTrigger';

import {chevronUp} from '../../svg';

class EntryVoteBtn extends Component {

  render() {
    return (
      <DownloadTrigger>
        <a className="btn-up-vote" role="none">
          <span className="btn-inner">{chevronUp}</span>
        </a>
      </DownloadTrigger>
    );
  }
}

EntryVoteBtn.defaultProps = {};

EntryVoteBtn.propTypes = {};

export default EntryVoteBtn;
