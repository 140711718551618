document.addEventListener('mouseover', function (event) {
  const {target} = event;
  if (!(target.tagName === 'A' && target.classList.contains('markdown-external-link'))) {
    return;
  }
  const href = target.getAttribute('data-href');
  if (target.getAttribute('title') !== href) {
    target.setAttribute('title', href);
  }
});

document.addEventListener('click', function (event) {

  let el = event.target;

  // A element can be wrapped with inline element. Look parent elements.
  while (el.tagName !== 'A') {
    if (!el.parentNode) {
      break;
    }
    el = el.parentNode;
  }

  if (!el || el.tagName !== 'A') {
    return;
  }

  if (el.getAttribute('target') === '_external') {
    const href = el.getAttribute('href');
    window.open(href);
    event.preventDefault();
    return false;
  }

  if (el.classList.contains('markdown-external-link')) {
    const href = el.getAttribute('data-href');
    window.open(href);
    event.preventDefault();
    return false;
  }


  if (el.classList.contains('markdown-author-link')) {
    event.preventDefault();
    if (event.path.filter(x => x.classList && x.classList.contains('no-click-event')).length > 0) {
      return false;
    }
    const author = el.getAttribute('data-author');
    const ev = new CustomEvent('md-author-clicked', {detail: {author}});
    window.dispatchEvent(ev);
    return false;
  }

  if (el.classList.contains('markdown-post-link')) {
    event.preventDefault();
    if (event.path.filter(x => x.classList && x.classList.contains('no-click-event')).length > 0) {
      return false;
    }
    let category = el.getAttribute('data-tag');
    let author = el.getAttribute('data-author');
    let permlink = el.getAttribute('data-permlink');
    const ev = new CustomEvent('md-post-clicked', {detail: {category, author, permlink}});
    window.dispatchEvent(ev);
    return false;
  }

  if (el.classList.contains('markdown-tag-link')) {
    event.preventDefault();
    if (event.path.filter(x => x.classList && x.classList.contains('no-click-event')).length > 0) {
      return false;
    }
    let tag = el.getAttribute('data-tag');
    const ev = new CustomEvent('md-tag-clicked', {detail: {tag}});
    window.dispatchEvent(ev);
    return false;
  }

  if (el.classList.contains('markdown-witnesses-link')) {
    event.preventDefault();
    const ev = new CustomEvent('md-witnesses-clicked', {});
    window.dispatchEvent(ev);
    return false;
  }

  if (el.classList.contains('markdown-video-link')) {
    const embedSrc = el.getAttribute('data-embed-src');
    if (embedSrc) {
      el.innerHTML = `<iframe frameborder='0' allowfullscreen src='${embedSrc}'></iframe>`;
      return;
    }
    const videoHref = el.getAttribute('data-video-href');
    if (videoHref) {
      window.open(videoHref);
      event.preventDefault();
      return false;
    }
  }

});


// Theme switcher
window.setTheme = function (theme) {
  const link = document.querySelector('#theme-css');
  const base = link.href.replace(/(day|night).css$/, '');
  const newHref = base + theme + '.css';

  link.setAttribute('href', newHref);

  document.querySelector('body').className = theme + '-theme';
};

window.onload = () => {
  const theme_ = localStorage.getItem('esteem_theme') ? JSON.parse(localStorage.getItem('esteem_theme')) : 'day';
  const theme = ['day', 'night'].includes(theme_) ? theme_ : 'day';
  window.setTheme(theme);
  document.querySelector('body').style.visibility = 'visible';
};