import React, {Component} from 'react';

import PropTypes from 'prop-types';

import comTag from '../../helpers/com-tag';


export const makePath = (filter, tag) => {
  return `/${filter}/${tag}`;
};

class TagLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: ''
    };
  }
  
  componentDidMount() {
    const { tag } = this.props;

    if (tag.startsWith('hive-')) {
      comTag(tag)
        .then(r => {
          this.setState({ label: r });
          return r;
        })
        .catch(() => {});
    }
  }
  
  clicked = (e) => {
    e.preventDefault();

    const {tag, global, location, history} = this.props;
    const {selectedFilter} = global;

    const newLoc = makePath(selectedFilter, tag);

    if (location.pathname === newLoc) {
      document.querySelector('#app-content').scrollTop = 0;
      return;
    }

    history.push(newLoc);
  };

  render() {
    const {children, global, tag} = this.props;
    const { label } = this.state;
    
    const {selectedFilter} = global;

    const href = makePath(selectedFilter, tag);
    
    const props = Object.assign({}, children.props, {href, onClick: this.clicked});
    
    if (label) {
      props.children = label;
    }

    return React.createElement('a', props);
  }
}

TagLink.propTypes = {
  global: PropTypes.shape({
    selectedFilter: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  tag: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default TagLink;
