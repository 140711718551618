const SET = 'visiting-account/SET';

const defaultState = null;

export default function visitingAccount(state = defaultState, action) {
  switch (action.type) {
    case SET: {
      const {accountData} = action.payload;

      return Object.assign({}, accountData);
    }
    default:
      return state;
  }
}


export const setVisitingAccount = accountData => dispatch => {
  dispatch({
    type: SET,
    payload: {accountData}
  });
};
