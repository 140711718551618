import defaults from '../constants/defaults';
import filters from '../constants/filters';


export const HOME_PATH = `/`;
export const ABOUT_PATH = `/about`;
export const GUESTS_PATH = `/guest-posts`;
export const CONTRIBUTE_PATH = `/contribute`;
export const SIGNUP_PATH = `/signup`;
export const WHITE_PAPER_PATH = `/whitepaper`;
export const PRIVACY_PATH = `/privacy-policy`;
export const TOS_PATH = `/terms-of-service`;
export const FILTER_PATH = `/:filter(${filters.join('|')})`;
export const FILTER_TAG_PATH = `/:filter(${filters.join('|')})/:tag`;
export const ENTRY_PATH = `/:category/:username(@[\\w\\.\\d-]+)/:permlink`;
export const USER_PATH = `/:username(@[\\w\\.\\d-]+)`;
export const USER_FEED_PATH = `/:username(@[\\w\\.\\d-]+)/:section(feed)`;
export const USER_SECTION_PATH = `/:username(@[\\w\\.\\d-]+)/:section(blog|comments|replies|wallet)`;

const pathToRegexp = require('path-to-regexp');

export const extractFilterTag = (location) => {

  if (location === HOME_PATH) {
    return {
      filter: defaults.filter,
      tag: ''
    }
  }

  if (/[A-Z]/.test(location)) {
    location = location.toLowerCase();
  }

  let re = pathToRegexp(FILTER_PATH);
  let params = re.exec(location);
  if (params && params[1]) {
    return {
      filter: params[1],
      tag: ''
    }
  }

  re = pathToRegexp(FILTER_TAG_PATH);
  params = re.exec(location);
  if (params && params[1] && params[2]) {
    return {
      filter: params[1],
      tag: params[2]
    }
  }

  re = pathToRegexp(USER_PATH);
  params = re.exec(location);
  if (params && params[1]) {
    return {
      filter: 'blog',
      tag: params[1]
    }
  }

  re = pathToRegexp(USER_FEED_PATH);
  params = re.exec(location);
  if (params && params[1] && params[2]) {
    return {
      filter: params[2],
      tag: params[1]
    }
  }

  re = pathToRegexp(USER_SECTION_PATH);
  params = re.exec(location);
  if (params && params[1] && params[2] && ['blog', 'comments', 'replies'].includes(params[2])) {
    return {
      filter: params[2],
      tag: params[1]
    }
  }

  return null;
};