import React, {Component} from 'react';

import {Route, Switch} from 'react-router-dom';
import Base from './Base';

import {IndexContainer, AboutContainer, WhitePaperContainer, PrivacyContainer, TosContainer, SignupContainer, GuestPostsContainer, ContributeContainer} from './Index'
import EntryIndexContainer from './EntryIndex';
import AccountContainer from './Account';
import EntryContainer from './Entry';
import NotFound from '../components/404';
import Analytics from 'react-router-ga';

import {
  HOME_PATH,
  ABOUT_PATH,
  GUESTS_PATH,
  CONTRIBUTE_PATH,
  SIGNUP_PATH,
  WHITE_PAPER_PATH,
  PRIVACY_PATH,
  TOS_PATH,
  FILTER_PATH,
  FILTER_TAG_PATH,
  ENTRY_PATH,
  USER_PATH,
  USER_FEED_PATH,
  USER_SECTION_PATH
} from '../helpers/route';

export default class Root extends Component {
  render() {
    return (
      <Base>
        <Analytics id="UA-150925333-1">
          <Switch>
            <Route exact path={HOME_PATH} component={IndexContainer}/>
            <Route exact path={ABOUT_PATH} component={AboutContainer}/>
            <Route exact path={GUESTS_PATH} component={GuestPostsContainer}/>
            <Route exact path={CONTRIBUTE_PATH} component={ContributeContainer}/>
            <Route exact path={SIGNUP_PATH} component={SignupContainer}/>
            <Route exact path={WHITE_PAPER_PATH} component={WhitePaperContainer}/>
            <Route exact path={PRIVACY_PATH} component={PrivacyContainer}/>
            <Route exact path={TOS_PATH} component={TosContainer}/>
            <Route exact strict path={FILTER_PATH} component={EntryIndexContainer}/>
            <Route exact strict path={FILTER_TAG_PATH} component={EntryIndexContainer}/>
            <Route exact path={ENTRY_PATH} component={EntryContainer}/>
            <Route exact strict path={USER_PATH} component={AccountContainer}/>
            <Route exact strict path={USER_FEED_PATH} component={AccountContainer}/>
            <Route exact strict path={USER_SECTION_PATH} component={AccountContainer}/>
            <Route component={NotFound}/>
          </Switch>
        </Analytics>
      </Base>
    );
  }
}