import React, { Component } from 'react';
import {injectIntl} from 'react-intl';
import SmartBanner from 'react-smartbanner'; // eslint-disable-line import/no-extraneous-dependencies

class AppView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      position: 'top',
      storeText: { ios: 'Faster, Easier, Complete', android: 'Faster, Easier, Complete' },
      price: { ios: 'FREE', android: 'FREE'},
      daysHidden: 7,
      daysReminder: 30,
      deviceType: ''
    };
  }
  render() {
    // TODO: open post or profile with esteem://
    // const { entry } = this.props;
    // TODO: desktop behaviour, similar 

    return <div className="aview">
      <SmartBanner 
        title={'Esteem'} 
        storeText={this.state.storeText} 
        position={this.state.position}
        price={this.state.price}
        daysHidden={this.state.daysHidden}
        daysReminder={this.state.daysReminder}
        //force={this.state.deviceType}
      /> 
    </div>
  }
}

export default injectIntl(AppView);
