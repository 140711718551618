import React, {Component} from 'react';

import {bindActionCreators} from 'redux';

import connect from 'react-redux/es/connect/connect';

import EntryIndex from '../components/EntryIndex';

import {fetchEntries} from '../store/entries';
import {fetchTrendingTags} from '../store/trending-tags';
import {fetchPromotedEntries} from '../store/promoted-entries';

import {
  changeListStyle,
  changeTheme
} from '../store/global';

import { setVisitingAccount } from '../store/visiting-account';
import {setVisitingEntry} from '../store/visiting-entry';


class EntryIndexContainer extends Component {
  render() {
    return <EntryIndex {...this.props} />;
  }
}

const mapStateToProps = ({global, trendingTags, promotedEntries, entries}) => ({
  global,
  trendingTags,
  promotedEntries,
  entries
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEntries,
      fetchTrendingTags,
      fetchPromotedEntries,
      changeListStyle,
      changeTheme,
      setVisitingAccount,
      setVisitingEntry
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryIndexContainer)