import {LOCATION_CHANGE} from 'react-router-redux';


import getSymbolFromCurrency from 'currency-symbol-map';

import {setItem, getItem} from '../helpers/storage';

import {setAddress} from '../backend/steem-client';

import defaults from '../constants/defaults';

import {extractFilterTag} from '../helpers/route';

import filters from '../constants/filters.json';


export const THEME_CHANGED = 'global/THEME_CHANGED';
export const LIST_STYLE_CHANGED = 'global/LIST_STYLE_CHANGED';
export const CURRENCY_CHANGED = 'global/CURRENCY_CHANGED';
export const LOCALE_CHANGED = 'global/LOCALE_CHANGED';
export const PUSH_NOTIFY_CHANGED = 'global/PUSH_NOTIFY_CHANGED';
export const SERVER_CHANGED = 'global/SERVER_CHANGED';

const defaultState = {
  selectedFilter: 'trending',
  selectedTag: '',
  theme: getItem('theme', defaults.theme),
  listStyle: getItem('list-style', defaults.listStyle),
  currency: getItem('currency', defaults.currency.currency),
  currencyRate: Number(getItem('currency-rate', defaults.currency.rate)),
  currencySymbol: getItem('currency-symbol', defaults.currency.symbol),
  locale: getItem('locale', defaults.locale),
  server: getItem('server', defaults.server),
};

/* Reducer */

export default function global(state = defaultState, action) {
  switch (action.type) {
    case '@@INIT':
    case LOCATION_CHANGE: {
      const u = new URL(window.location.href);
      const pathname = u.pathname;
      const params = extractFilterTag(pathname);

      if (!params) {
        return state;
      }

      const {filter, tag} = params;

      if (!filters.includes(filter)) {
        return state;
      }

      return Object.assign({}, state, {
        selectedFilter: filter,
        selectedTag: tag
      });
    }
    case THEME_CHANGED: {
      const {newTheme} = action.payload;
      return Object.assign({}, state, {
        theme: newTheme
      });
    }
    case LIST_STYLE_CHANGED: {
      const {newStyle} = action.payload;
      return Object.assign({}, state, {
        listStyle: newStyle
      });
    }
    case CURRENCY_CHANGED: {
      const {currency, currencyRate, currencySymbol} = action.payload;

      return Object.assign({}, state, {
        currency,
        currencyRate,
        currencySymbol
      });
    }
    case LOCALE_CHANGED: {
      const {locale} = action.payload;

      return Object.assign({}, state, {
        locale
      });
    }
    case PUSH_NOTIFY_CHANGED: {
      const {val} = action.payload;

      return Object.assign({}, state, {
        pushNotify: val
      });
    }
    case SERVER_CHANGED: {
      const {server} = action.payload;

      return Object.assign({}, state, {
        server
      });
    }
    default:
      return state;
  }
}

/* Actions */
export const changeTheme = () => (dispatch, getState) => {
  const {global} = getState();

  const {theme} = global;
  const newTheme = theme === 'day' ? 'night' : 'day';

  setItem('theme', newTheme);
  window.setTheme(newTheme);

  dispatch(themeChanged(newTheme));
};

export const changeListStyle = () => (dispatch, getState) => {
  const {global} = getState();

  const {listStyle} = global;

  const newStyle = listStyle === 'row' ? 'grid' : 'row';

  setItem('list-style', newStyle);

  dispatch(listStyleChanged(newStyle));
};

export const changeCurrency = (currency, currencyRate) => dispatch => {
  const symbol = getSymbolFromCurrency(currency);

  setItem('currency', currency);
  setItem('currency-rate', currencyRate);
  setItem('currency-symbol', symbol);

  dispatch(currencyChanged(currency, currencyRate, symbol));
};

export const changeLocale = locale => dispatch => {
  setItem('locale', locale);

  dispatch(localeChanged(locale));
};

export const changePushNotify = val => dispatch => {
  setItem('push-notify', val);

  dispatch(pushNotifyChanged(val));
};

export const changeServer = server => dispatch => {
  setItem('server', server);

  setAddress(server);

  dispatch(serverChanged(server));
};

/* Action creators */

export const themeChanged = newTheme => ({
  type: THEME_CHANGED,
  payload: {newTheme}
});

export const listStyleChanged = newStyle => ({
  type: LIST_STYLE_CHANGED,
  payload: {newStyle}
});

export const currencyChanged = (currency, currencyRate, currencySymbol) => ({
  type: CURRENCY_CHANGED,
  payload: {currency, currencyRate, currencySymbol}
});

export const localeChanged = locale => ({
  type: LOCALE_CHANGED,
  payload: {locale}
});

export const pushNotifyChanged = val => ({
  type: PUSH_NOTIFY_CHANGED,
  payload: {val}
});

export const serverChanged = server => ({
  type: SERVER_CHANGED,
  payload: {server}
});
