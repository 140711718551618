import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAccount} from '../../backend/steem-client';


export const makePath = username => `/@${username}`;

class AccountLink extends Component {
  goProfile = async (e) => {
    e.preventDefault();

    const {username, history, setVisitingAccount, onClick, afterClick} = this.props;

    onClick();

    let {accountData} = this.props;

    if (!accountData) {
      try {
        accountData = await getAccount(username);
      } catch (err) {
        accountData = null;
      }
    }

    if (accountData) {
      setVisitingAccount(accountData);
    }

    history.push(makePath(username));

    afterClick();
  };

  render() {
    const {children, username} = this.props;
    const href = makePath(username);

    const props = Object.assign({}, children.props, {href, onClick: this.goProfile});

    return React.createElement('a', props);
  }
}

AccountLink.defaultProps = {
  accountData: null,
  onClick: () => {
  },
  afterClick: () => {
  }
};

AccountLink.propTypes = {
  children: PropTypes.element.isRequired,
  username: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  accountData: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  afterClick: PropTypes.func,
  setVisitingAccount: PropTypes.func.isRequired
};

export default AccountLink;
