import React, {Component} from "react";

export default class NotFound extends Component {
  render() {
    return (
      <div className="error-404">
        <h1>404</h1>
        <p>The page you are looking for does not exist</p>
      </div>
    )
  }
}