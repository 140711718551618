import {Client} from '@hiveio/dhive';

const DEFAULT_SERVER = [
  'https://api.hive.blog',
  'https://rpc.ecency.com',
];
let client = new Client(DEFAULT_SERVER, {
  timeout: 4000,
  failoverThreshold: 10,
  consoleOnFailover: true
});

export const TT_FETCH_BEGIN = 'TT_FETCH_BEGIN';
export const TT_FETCH_OK = 'TT_FETCH_OK';
export const TT_FETCH_ERROR = 'TT_FETCH_ERROR';

const defaultState = {
  list: [],
  loading: false,
  error: false
};

/* Reducer */

export default function trendingTags(state = defaultState, action) {
  switch (action.type) {
    case TT_FETCH_BEGIN:
      return {
        list: [],
        loading: true,
        error: false
      };
    case TT_FETCH_OK:
      return {
        list: action.payload.filter(x => x.name !== '').map(x => x.name),
        loading: false,
        error: false
      };
    case TT_FETCH_ERROR:
      return {
        list: [],
        loading: false,
        error: true
      };
    default:
      return state;
  }
}

const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}


/* Actions */
export function fetchTrendingTags(afterTag = '', limit = 100) {
  return (dispatch, getState) => {
    const {trendingTags} = getState();

    if (trendingTags.list.length >= 1) {
      return;
    }

    dispatch(fetchBegin());

    client.database
      .call('get_trending_tags', [afterTag, limit])
      .then(resp => {
        const sresp = shuffle(resp);

        dispatch(fetchOk(sresp));

        return sresp;
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
}

/* Actions creators */

export const fetchBegin = () => ({
  type: TT_FETCH_BEGIN
});

export const fetchOk = payload => ({
  type: TT_FETCH_OK,
  payload
});

export const fetchError = () => ({
  type: TT_FETCH_ERROR
});
