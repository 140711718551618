import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {getContent} from '../../backend/steem-client';


export const makePath = (category, author, permlink, toReplies = false) =>
  `/${category}/@${author}/${permlink}${toReplies ? '#replies' : ''}`;

class EntryLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entry: null
    }
  }

  componentDidMount() {
    let {entry} = this.props;
    if (entry) {
      this.setState({entry});
      return;
    }

    const {author, permlink} = this.props;
    getContent(author, permlink).then(resp => {
      this.setState({entry: resp});
    });
  }

  goEntry = async (e) => {
    e.preventDefault();

    let {entry} = this.props;
    const {history, toReplies, setVisitingEntry} = this.props;

    if (!entry) {
      const {author, permlink} = this.props;
      entry = await getContent(author, permlink);
    }

    if (entry) {
      const {category, author, permlink} = entry;
      setVisitingEntry(entry);
      history.push(makePath(category, author, permlink, toReplies));
    }
  };

  render() {
    const {children} = this.props;
    const {entry} = this.state;

    if (!entry) {
      return null;
    }

    const href = makePath(entry.category, entry.author, entry.permlink);

    const props = Object.assign({}, children.props, {href, onClick: this.goEntry});

    return React.createElement('a', props);
  }
}

EntryLink.defaultProps = {
  entry: null,
  toReplies: false
};

EntryLink.propTypes = {
  children: PropTypes.element.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  entry: PropTypes.shape({
    category: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    permlink: PropTypes.string.isRequired
  }),
  author: PropTypes.string.isRequired,
  permlink: PropTypes.string.isRequired,
  toReplies: PropTypes.bool,
  setVisitingEntry: PropTypes.func.isRequired
};

export default EntryLink;
