/*
eslint-disable jsx-a11y/anchor-is-valid
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ListSwitch extends Component {
  changeStyle = () => {
    const {changeListStyle} = this.props;

    changeListStyle();
  };

  render() {
    const {global} = this.props;
    const {listStyle} = global;

    return (
      <a
        className={`list-switch ${listStyle === 'grid' ? 'active' : ''}`}
        onClick={() => {
          this.changeStyle();
        }}
        role="none"
      >
        <i className="mi">view_module</i>
      </a>
    );
  }
}

ListSwitch.propTypes = {
  changeListStyle: PropTypes.func.isRequired,
  global: PropTypes.shape({
    listStyle: PropTypes.string.isRequired
  }).isRequired
};

export default ListSwitch;
