import React from 'react';
import {render} from 'react-dom';

import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import store, {history} from './store';

import Root from './containers/Root';
import * as serviceWorker from './serviceWorker';

import './style/style.scss';

require('./handlers');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Root/>
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
