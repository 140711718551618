/*
eslint-disable jsx-a11y/anchor-is-valid, react/style-prop-object
*/

import React, {Component} from 'react';

import PropTypes from 'prop-types';

import message from 'antd/lib/message';
import Button from "antd/lib/button";

import {
  FormattedNumber,
  FormattedDate,
  FormattedMessage,
  FormattedRelative,
  injectIntl
} from 'react-intl';

import {makeGroupKeyForEntries} from '../store/entries';

import {proxifyImageSrc} from '@esteemapp/esteem-render-helpers';

import NavBar from './layout/NavBar';

import Tooltip from './common/Tooltip';
import LinearProgress from './common/LinearProgress';

import UserAvatar from './elements/UserAvatar';
import EntryListItem from './elements/EntryListItem';
import EntryListLoadingItem from './elements/EntryListLoadingItem';
import ListSwitch from './elements/ListSwitch';
import AppView from './elements/AppView';

import DelegationListModal from './dialogs/DelegatedList';
import DelegateeListModal from './dialogs/DelegateeList';

import DownloadTrigger from './helpers/DownloadTrigger';

import NotFound from './404';

import formatChainError from '../utils/format-chain-error';
import parseToken from '../utils/parse-token';
import parseDate from '../utils/parse-date';
import {vestsToSp} from '../utils/conversions';

import authorReputation from '../utils/author-reputation';
import {votingPower} from '../utils/manabar';

import coverFallbackDay from '../img/cover-fallback-day.png';
import coverFallbackNight from '../img/cover-fallback-night.png';

import {
  getFollowCount,
  getAccount,
  getState
} from '../backend/steem-client';

import FriendsModal from './dialogs/Friends';
import ScrollReplace from './helpers/ScrollReplace';


class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      followersModalVisible: false,
      followingModalVisible: false
    };
  }

  render() {
    let vPower;
    let vPowerPercentage;
    let reputation;
    let name;
    let about;
    let postCount;
    let followerCount;
    let followingCount;
    let location;
    let website;
    let created;

    const {followersModalVisible, followingModalVisible} = this.state;
    const {username, account, intl} = this.props;
    const rss_link = "https://esteem.app/@"+username+"/rss";

    if (account) {
      vPower = votingPower(account);
      vPowerPercentage = `${parseInt(vPower, 10)}%`;
      reputation = authorReputation(account.reputation);
      postCount = account.post_count;
      ({followerCount} = account);
      ({followingCount} = account);

      const {accountProfile} = account;
      if (accountProfile) {
        name = accountProfile.name || null;
        about = accountProfile.about || null;
        location = accountProfile.location || null;
        website = accountProfile.website || null;
      }

      created = new Date(account.created);
    }

    return (
      <div className="profile-area">
        <div className="account-avatar">
          <UserAvatar user={username} size="xLarge"/>
          {reputation && <div className="reputation">{reputation}</div>}
        </div>

        <div className="username">{username}</div>

        {vPowerPercentage && (
          <div className="vpower-line">
            <div
              className="vpower-line-inner"
              style={{width: vPowerPercentage}}
            />
          </div>
        )}

        {vPower && (
          <div className="vpower-percentage">
            <Tooltip
              title={intl.formatMessage({
                id: 'account.voting-power'
              })}
            >
              {vPower.toFixed(2)}
            </Tooltip>
          </div>
        )}

        {name && <div className="full-name">{name}</div>}

        {about && <div className="about">{about}</div>}

        {(name || about) && <div className="divider"/>}

        <div className="account-numbers">
          <div className="account-prop">
            <Tooltip
              title={intl.formatMessage({
                id: 'account.post-count'
              })}
              className="holder-tooltip"
            >
              <i className="mi">list</i>
              {typeof postCount === 'number' ? (
                <FormattedNumber value={postCount}/>
              ) : (
                <span>--</span>
              )}
            </Tooltip>
          </div>
          <div className="account-prop">

          </div>
          <div className="account-prop">
            <FriendsModal
              count={followerCount}
              visible={followersModalVisible}
              mode="followers"
              username={username}
              onCancel={() => {
                this.setState({followersModalVisible: false});
              }}
              {...this.props}
            />
            <Tooltip
              title={intl.formatMessage({
                id: 'account.followers'
              })}
              className="holder-tooltip"
            >
              <i className="mi">people</i>
              {typeof followerCount === 'number' ? (
                <span
                  className="with-pointer"
                  role="none"
                  onClick={() => {
                    this.setState({followersModalVisible: true});
                  }}
                >
                  <FormattedNumber value={followerCount}/>
                </span>
              ) : (
                <span>--</span>
              )}
            </Tooltip>
          </div>
          <div className="account-prop">
            <FriendsModal
              count={followingCount}
              visible={followingModalVisible}
              mode="following"
              username={username}
              onCancel={() => {
                this.setState({followingModalVisible: false});
              }}
              {...this.props}
            />
            <Tooltip
              title={intl.formatMessage({
                id: 'account.following'
              })}
              className="holder-tooltip"
            >
              <i className="mi">person_add</i>
              {typeof followingCount === 'number' ? (
                <span
                  className="with-pointer"
                  role="none"
                  onClick={() => {
                    this.setState({followingModalVisible: true});
                  }}
                >
                  <FormattedNumber value={followingCount}/>
                </span>
              ) : (
                <span>--</span>
              )}
            </Tooltip>
          </div>
        </div>

        <div className="divider"/>

        {location && (
          <div className="account-prop">
            <i className="mi">near_me</i> {location}
          </div>
        )}

        {website && (
          <div className="account-prop prop-website">
            <i className="mi">public</i>{' '}
            <a target="_external" className="website-link" href={website}>
              {website}
            </a>
          </div>
        )}

        {created && (
          <div className="account-prop">
            <i className="mi">date_range</i>{' '}
            <FormattedDate
              month="long"
              day="2-digit"
              year="numeric"
              value={created}
            />
          </div>
        )}

        <div className="account-prop">
            <i className="mi">rss_feed</i>{' '}
            <a target="_external" className="website-link" href={rss_link}>
              RSS feed
             </a>
          </div>
      </div>
    );
  }
}

Profile.defaultProps = {
  account: null,
  activeAccount: null
};

Profile.propTypes = {
  username: PropTypes.string.isRequired,
  account: PropTypes.instanceOf(Object),
  intl: PropTypes.instanceOf(Object).isRequired,
  activeAccount: PropTypes.instanceOf(Object)
};

export class AccountMenu extends Component {
  goSection = section => {
    const {history, username} = this.props;
    const u = section ? `/@${username}/${section}` : `/@${username}`;
    history.push(u);
  };

  render() {
    const {section} = this.props;

    return (
      <div className="account-menu">
        <div className="account-menu-items">
          <a
            role="none"
            className={`menu-item ${section === 'blog' && 'selected-item'}`}
            onClick={() => {
              this.goSection('blog');
            }}
          >
            <FormattedMessage id="account.section-blog"/>
          </a>
          <a
            role="none"
            className={`menu-item ${section === 'comments' && 'selected-item'}`}
            onClick={() => {
              this.goSection('comments');
            }}
          >
            <FormattedMessage id="account.section-comments"/>
          </a>
          <a
            role="none"
            className={`menu-item ${section === 'replies' && 'selected-item'}`}
            onClick={() => {
              this.goSection('replies');
            }}
          >
            <FormattedMessage id="account.section-replies"/>
          </a>
          <a
            role="none"
            className={`menu-item ${section === 'wallet' && 'selected-item'}`}
            onClick={() => {
              this.goSection('wallet');
            }}
          >
            <FormattedMessage id="account.section-wallet"/>
          </a>
        </div>
        <div className="page-tools">
          {section !== 'wallet' && <ListSwitch {...this.props} />}
        </div>
      </div>
    );
  }
}

AccountMenu.propTypes = {
  username: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

export class AccountCover extends Component {
  render() {
    let coverImage;

    const {account, global} = this.props;

    if (account) {
      const {accountProfile} = account;
      if (accountProfile) {
        coverImage = accountProfile.cover_image || null;
      }
    }

    const bgImage =
      (coverImage && proxifyImageSrc(coverImage)) ||
      (global.theme === 'day' ? coverFallbackDay : coverFallbackNight);

    return (
      <div className="account-cover">
        <div
          className="cover-image"
          style={{backgroundImage: `url('${bgImage}')`}}
        />
        <div className="follow-controls-holder">
          <DownloadTrigger>
            <Button
              type="primary"
              style={{marginRight: '5px'}}
            >
              <FormattedMessage id="follow-controls.follow"/>
            </Button>
          </DownloadTrigger>
        </div>
      </div>
    );
  }
}

AccountCover.defaultProps = {
  account: null,
  activeAccount: null
};

AccountCover.propTypes = {
  username: PropTypes.string.isRequired,
  account: PropTypes.instanceOf(Object),
  global: PropTypes.shape({
    theme: PropTypes.string.isRequired
  }).isRequired,
  activeAccount: PropTypes.instanceOf(Object)
};


export class TransactionRow extends Component {
  render() {
    const {dynamicProps, transaction: tr} = this.props;
    const {steemPerMVests} = dynamicProps;

    const {op} = tr[1];
    const {timestamp} = tr[1];
    const opName = op[0];
    const opData = op[1];
    const transDate = parseDate(timestamp);

    let flag = false;
    let icon = 'local_activity';
    let numbers;
    let details;

    if (opName === 'curation_reward') {
      flag = true;

      const {reward: vestingPayout} = opData;

      numbers = (
        <>
          <FormattedNumber
            value={vestsToSp(parseToken(vestingPayout), steemPerMVests)}
            minimumFractionDigits={3}
          />{' '}
          {'HP'}
        </>
      );

      const {
        comment_author: commentAuthor,
        comment_permlink: commentPermlink
      } = opData;
      details = `@${commentAuthor}/${commentPermlink}`;
    }

    if (opName === 'author_reward' || opName === 'comment_benefactor_reward') {
      flag = true;

      let {
        sbd_payout: sbdPayout,
        steem_payout: steemPayout,
        vesting_payout: vestingPayout
      } = opData;

      sbdPayout = parseToken(sbdPayout);
      steemPayout = parseToken(steemPayout);
      vestingPayout = parseToken(vestingPayout);

      numbers = (
        <>
          {sbdPayout > 0 && (
            <span className="number">
              <FormattedNumber value={sbdPayout} minimumFractionDigits={3}/>{' '}
              {'HBD'}
            </span>
          )}
          {steemPayout > 0 && (
            <span className="number">
              <FormattedNumber value={steemPayout} minimumFractionDigits={3}/>{' '}
              {'HIVE'}
            </span>
          )}
          {vestingPayout > 0 && (
            <span className="number">
              <FormattedNumber
                value={vestsToSp(vestingPayout, steemPerMVests)}
                minimumFractionDigits={3}
              />{' '}
              {'HP'}
            </span>
          )}
        </>
      );

      const {author, permlink} = opData;

      details = `@${author}/${permlink}`;
    }

    if (opName === 'comment_benefactor_reward') {
      icon = 'comment';
    }

    if (opName === 'claim_reward_balance') {
      flag = true;

      let {
        reward_sbd: rewardSbd,
        reward_steem: rewardSteem,
        reward_vests: rewardVests
      } = opData;

      rewardSbd = parseToken(rewardSbd);
      rewardSteem = parseToken(rewardSteem);
      rewardVests = parseToken(rewardVests);

      numbers = (
        <>
          {rewardSbd > 0 && (
            <span className="number">
              <FormattedNumber value={rewardSbd} minimumFractionDigits={3}/>{' '}
              {'HBD'}
            </span>
          )}
          {rewardSteem > 0 && (
            <span className="number">
              <FormattedNumber value={rewardSteem} minimumFractionDigits={3}/>{' '}
              {'HIVE'}
            </span>
          )}
          {rewardVests > 0 && (
            <span className="number">
              <FormattedNumber
                value={vestsToSp(rewardVests, steemPerMVests)}
                minimumFractionDigits={3}
              />{' '}
              {'HP'}
            </span>
          )}
        </>
      );
    }

    if (opName === 'transfer' || opName === 'transfer_to_vesting') {
      flag = true;
      icon = 'compare_arrows';

      const {amount, memo, from, to} = opData;
      details = (
        <span>
          {memo} <br/>
          <br/> <strong>@{from}</strong> -&gt; <strong>@{to}</strong>
        </span>
      );

      numbers = <span>{amount}</span>;
    }

    if (opName === 'withdraw_vesting') {
      flag = true;
      icon = 'money';

      const {acc} = opData;

      let {vesting_shares: opVestingShares} = opData;

      opVestingShares = parseToken(opVestingShares);
      numbers = (
        <span className="number">
          <FormattedNumber
            value={vestsToSp(opVestingShares, steemPerMVests)}
            minimumFractionDigits={3}
          />{' '}
          {'HP'}
        </span>
      );

      details = acc ? (
        <span>
          <strong>@{acc}</strong>
        </span>
      ) : null;
    }

    if (opName === 'fill_order') {
      flag = true;
      icon = 'reorder';

      const {current_pays: currentPays, open_pays: openPays} = opData;

      numbers = (
        <span className="number">
          {currentPays} = {openPays}
        </span>
      );
    }

    if (flag) {
      return (
        <div className="transaction-list-item">
          <div className="transaction-icon">
            <i className="mi">{icon}</i>
          </div>
          <div className="transaction-title">
            <div className="transaction-name">
              <FormattedMessage id={`account.transaction-${opName}`}/>
            </div>
            <div className="transaction-date">
              <FormattedRelative value={transDate}/>
            </div>
          </div>
          <div className="transaction-numbers">{numbers}</div>
          <div className="transaction-details">{details}</div>
        </div>
      );
    }

    return null;
  }
}

TransactionRow.propTypes = {
  transaction: PropTypes.arrayOf(Object).isRequired,
  dynamicProps: PropTypes.instanceOf(Object).isRequired
};


export class SectionWallet extends Component {
  constructor(props) {
    super(props);

    const {account} = this.props;

    this.state = {
      account,
      claiming: false,
      delegatedModalOpen: false,
      delegateeModalOpen: false
    };
  }

  toggleDelegatedModal = () => {
    const {delegatedModalOpen} = this.state;
    this.setState({delegatedModalOpen: !delegatedModalOpen});
  };
  
  toggleDelegateeModal = () => {
    const { delegateeModalOpen } = this.state;
    this.setState({ delegateeModalOpen: !delegateeModalOpen });
  };

  render() {
    const {
      transactions,
      dynamicProps,
      global,
      intl,
      activeAccount,
      username
    } = this.props;
    const {
      account,
      claiming,
      delegatedModalOpen,
      delegateeModalOpen
    } = this.state;

    if (account) {
      const {steemPerMVests, base, quote} = dynamicProps;
      const {currency} = global;

      const rewardSteemBalance = parseToken(account.reward_steem_balance);
      const rewardSbdBalance = parseToken(account.reward_sbd_balance);
      const rewardVestingSteem = parseToken(account.reward_vesting_steem);
      const hasUnclaimedRewards =
        rewardSteemBalance > 0 ||
        rewardSbdBalance > 0 ||
        rewardVestingSteem > 0;

      const balance = parseToken(account.balance);

      const vestingShares = parseToken(account.vesting_shares);
      const vestingSharesDelegated = parseToken(
        account.delegated_vesting_shares
      );
      const vestingSharesReceived = parseToken(account.received_vesting_shares);

      const sbdBalance = parseToken(account.sbd_balance);
      const savingBalance = parseToken(account.savings_balance);
      const savingBalanceSbd = parseToken(account.savings_sbd_balance);

      const pricePerSteem = base / quote;

      const totalSteem =
        vestsToSp(vestingShares, steemPerMVests) + balance + savingBalance;

      const totalSbd = sbdBalance + savingBalanceSbd;

      const estimatedValue = totalSteem * pricePerSteem + totalSbd;

      const showPowerDown =
        account.next_vesting_withdrawal !== '1969-12-31T23:59:59';
      const nextVestingWithdrawal = parseDate(account.next_vesting_withdrawal);
      // Math.min: 14th week powerdown: https://github.com/steemit/steem/issues/3237
      // "?:": to_withdraw & withdrawn is integer 0 not string with no powerdown
      const vestingSharesWithdrawal = showPowerDown
        ? Math.min(
          parseToken(account.vesting_withdraw_rate),
          (parseToken(account.to_withdraw) - parseToken(account.withdrawn)) /
          100000
        )
        : 0;
      const vestingSharesTotal =
        vestingShares -
        vestingSharesDelegated +
        vestingSharesReceived -
        vestingSharesWithdrawal;

      const isMyPage = activeAccount && activeAccount.username === username;

      return (
        <div className="wallet-section">
          <div className="first-row">
            {hasUnclaimedRewards && (
              <div className="unclaimed-rewards">
                <div className="title">
                  <FormattedMessage id="account.unclaimed-rewards"/>
                </div>
                <div className="rewards">
                  {rewardSteemBalance > 0 && (
                    <span className="reward-type">{`${rewardSteemBalance} HIVE`}</span>
                  )}
                  {rewardSbdBalance > 0 && (
                    <span className="reward-type">{`${rewardSbdBalance} HBD`}</span>
                  )}
                  {rewardVestingSteem > 0 && (
                    <span className="reward-type">{`${rewardVestingSteem} HP`}</span>
                  )}
                  {isMyPage && (
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'account.claim-reward-balance'
                      })}
                      mouseEnterDelay={2}
                    >
                      <a
                        className={`claim-btn ${claiming ? 'in-progress' : ''}`}
                        onClick={() => {
                          this.claimRewardBalance();
                        }}
                        role="none"
                      >
                        <i className="mi">add_circle</i>
                      </a>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
            <div className="estimated-value">
              <Tooltip
                title={intl.formatMessage({
                  id: 'account.estimated-value'
                })}
              >
                <span>
                  <FormattedNumber
                    currency={currency}
                    style="currency"
                    currencyDisplay="symbol"
                    minimumFractionDigits={3}
                    value={estimatedValue}
                  />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="second-row">
            <div className="funds">
              <div className="fund fund-steem">
                <div className="fund-line">
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'account.steem-description'
                    })}
                  >
                    <div className="fund-info-icon"/>
                  </Tooltip>
                  <div className="fund-title">
                    <FormattedMessage id="account.steem"/>
                  </div>
                  <div className="fund-number">
                    <FormattedNumber
                      value={balance}
                      minimumFractionDigits={3}
                    />{' '}
                    {'HIVE'}
                  </div>

                </div>
              </div>

              <div className="fund fund-sp alternative">
                <div className="fund-line">
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'account.steem-power-description'
                    })}
                  >
                    <div className="fund-info-icon"/>
                  </Tooltip>
                  <div className="fund-title">
                    <FormattedMessage id="account.steem-power"/>
                  </div>
                  <div className="fund-number">
                    <FormattedNumber
                      value={vestsToSp(vestingShares, steemPerMVests)}
                      minimumFractionDigits={3}
                    />{' '}
                    {'HP'}
                  </div>

                </div>

                {vestingSharesDelegated > 0 && (
                  <div className="fund-line">
                    <div className="fund-number delegated-shares">
                      <Tooltip
                        title={intl.formatMessage({
                          id: 'account.steem-power-delegated'
                        })}
                      >
                        <span
                          className="btn-delegated"
                          role="none"
                          onClick={this.toggleDelegatedModal}
                        >
                          {'-'}{' '}
                          <FormattedNumber
                            value={vestsToSp(
                              vestingSharesDelegated,
                              steemPerMVests
                            )}
                            minimumFractionDigits={3}
                          />{' '}
                          {'HP'}
                        </span>
                      </Tooltip>
                    </div>

                  </div>
                )}

                {vestingSharesReceived > 0 && (
                  <div className="fund-line">
                    <div className="fund-number received-shares">
                      <Tooltip
                        title={intl.formatMessage({
                          id: 'account.steem-power-received'
                        })}
                      >
                        <span
                          className="btn-delegatee"
                          role="none"
                          onClick={this.toggleDelegateeModal}
                        >
                          {'+'}{' '}
                          <FormattedNumber
                            value={vestsToSp(
                              vestingSharesReceived,
                              steemPerMVests
                            )}
                            minimumFractionDigits={3}
                          />{' '}
                          {'HP'}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                )}

                {vestingSharesWithdrawal > 0 && (
                  <div className="fund-line">
                    <div className="fund-number next-power-down-amount">
                      <Tooltip
                        title={intl.formatMessage({
                          id: 'account.next-power-down-amount'
                        })}
                      >
                        {'-'}{' '}
                        <FormattedNumber
                          value={vestsToSp(
                            vestingSharesWithdrawal,
                            steemPerMVests
                          )}
                          minimumFractionDigits={3}
                        />{' '}
                        {'HP'}
                      </Tooltip>
                    </div>
                  </div>
                )}

                {(vestingSharesDelegated > 0 ||
                  vestingSharesReceived > 0 ||
                  vestingSharesWithdrawal > 0) && (
                  <div className="fund-line">
                    <div className="fund-number total-sp">
                      <Tooltip
                        title={intl.formatMessage({
                          id: 'account.steem-power-total'
                        })}
                      >
                        {'='}{' '}
                        <FormattedNumber
                          value={vestsToSp(vestingSharesTotal, steemPerMVests)}
                          minimumFractionDigits={3}
                        />{' '}
                        {'HP'}
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>

              <div className="fund fund-sbd">
                <div className="fund-line">
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'account.steem-dollars-description'
                    })}
                  >
                    <div className="fund-info-icon"/>
                  </Tooltip>
                  <div className="fund-title">
                    <FormattedMessage id="account.steem-dollars"/>
                  </div>
                  <div className="fund-number">
                    <FormattedNumber
                      currency="USD"
                      style="currency"
                      currencyDisplay="symbol"
                      minimumFractionDigits={3}
                      value={sbdBalance}
                    />
                  </div>
                </div>
              </div>
              <div className="fund fund-savings alternative">
                <div className="fund-line">
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'account.savings-description'
                    })}
                  >
                    <div className="fund-info-icon"/>
                  </Tooltip>
                  <div className="fund-title">
                    <FormattedMessage id="account.savings"/>
                  </div>
                  <div className="fund-number">
                    <FormattedNumber
                      minimumFractionDigits={3}
                      value={savingBalance}
                    />{' '}
                    {'HIVE'}
                  </div>
                </div>
                <div className="fund-line">
                  <div className="fund-number">
                    <FormattedNumber
                      currency="USD"
                      style="currency"
                      currencyDisplay="symbol"
                      minimumFractionDigits={3}
                      value={savingBalanceSbd}
                    />
                  </div>
                </div>
              </div>
              {showPowerDown && (
                <div className="next-power-down">
                  <div className="fund-info-icon"/>
                  <FormattedMessage
                    id="account.next-power-down"
                    values={{
                      time: <FormattedRelative value={nextVestingWithdrawal}/>,
                      amount: (
                        <strong>
                          <FormattedNumber
                            value={vestsToSp(
                              vestingSharesWithdrawal,
                              steemPerMVests
                            )}
                            minimumFractionDigits={3}
                          />{' '}
                          HP
                        </strong>
                      )
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="transaction-list">
            <div className="transaction-list-header">
              <h2>
                <FormattedMessage id="account.transactions"/>
              </h2>
            </div>
            <div className="transaction-list-body">
              {transactions &&
              transactions.map(tr => (
                <TransactionRow
                  {...this.props}
                  transaction={tr}
                  key={tr[0]}
                />
              ))}
            </div>
          </div>

          <DelegationListModal
            {...this.props}
            username={username}
            visible={delegatedModalOpen}
            onCancel={this.toggleDelegatedModal}
          />

          <DelegateeListModal
            {...this.props}
            username={username}
            visible={delegateeModalOpen}
            onCancel={this.toggleDelegateeModal}
          />
        </div>
      );
    }

    return <div className="wallet-section"/>;
  }
}

SectionWallet.defaultProps = {
  account: null,
  transactions: [],
  activeAccount: null
};

SectionWallet.propTypes = {
  username: PropTypes.string.isRequired,
  account: PropTypes.instanceOf(Object),
  transactions: PropTypes.arrayOf(Object),
  dynamicProps: PropTypes.shape({
    steemPerMVests: PropTypes.number.isRequired,
    base: PropTypes.number.isRequired
  }).isRequired,
  global: PropTypes.shape({
    currencySymbol: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired
};


class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null,
      notFound: false,
      transactions: null,
      transactionsLoading: true
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchEntries();
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props;

    if (location !== prevProps.location) {
      // fetch entries when location changed.
      this.fetchEntries();

      const {match: newMatch} = this.props;
      const {match: oldMatch} = prevProps;

      const {username: newUsername} = newMatch.params;
      const {username: oldUsername} = oldMatch.params;

      if (newUsername !== oldUsername) {
        // refresh data when user changed
        this.fetchData();
      }
    }
  }

  fetchData = () => {
    // Reset state
    this.setState({
      account: null,
      notFound: false,
      transactions: null,
      transactionsLoading: true
    });

    // Account data
    this.fetchAccount()
      .then(account => {
        if (account) {
          this.setState({account});
        } else {
          this.setState({notFound: true});
        }
        return account;
      })
      .catch(err => {
        message.error(formatChainError(err));
      });

    const {match} = this.props;
    let {username} = match.params;
    username = username.replace('@', '');

    // Transactions
    return getState(`/@${username}/transfers`)
      .then(state => {
        const {accounts} = state;

        if (Object.keys(accounts).length === 0) {
          return;
        }

        const {transfer_history: transferHistory} = accounts[username];
        const transactions = transferHistory.slice(
          Math.max(transferHistory.length - 50, 0)
        );
        transactions.sort((a, b) => b[0] - a[0]);
        return transactions;
      })
      .then(transactions => {
        this.setState({
          transactions
        });
        return transactions;
      })
      .catch(err => {
        message.error(formatChainError(err));
      })
      .finally(() => {
        this.setState({
          transactionsLoading: false
        });
      });
  };

  fetchAccount = async () => {
    const {match} = this.props;
    let {username} = match.params;
    username = username.replace('@', '');

    let {visitingAccount: account} = this.props;

    if (!(account && account.name === username)) {
      account = await getAccount(username);
    }

    if (!account) {
      return null;
    }

    // Profile data
    let accountProfile;
    try {
      accountProfile = JSON.parse(account.json_metadata).profile;
    } catch (err) {
      accountProfile = null;
    }

    account = Object.assign({}, account, {accountProfile});
    this.setState({account});

    // Follow counts
    let follow;
    try {
      follow = await getFollowCount(username);
    } catch (err) {
      follow = null;
    }

    if (follow) {
      const followerCount = follow.follower_count;
      const followingCount = follow.following_count;

      account = Object.assign({}, account, {followerCount, followingCount});
      this.setState({account});
    }

    account = Object.assign({}, account);

    return account;
  };

  fetchEntries = () => {
    const {match, fetchEntries} = this.props;
    const {username, section = 'blog'} = match.params;

    if (['blog', 'comments', 'replies'].includes(section)) {
      fetchEntries(section, username);
    }
  };

  bottomReached = () => {
    const {fetchEntries, entries, match} = this.props;
    const {username, section = 'blog'} = match.params;

    const groupKey = makeGroupKeyForEntries(section, `${username}`);
    const data = entries.get(groupKey);
    const loading = data.get('loading');
    const hasMore = data.get('hasMore');

    if (!loading && hasMore) {
      fetchEntries(section, `${username}`, true);
    }
  };

  render() {
    const {entries, global, match} = this.props;
    const {account, notFound} = this.state;
    let {username, section = 'blog'} = match.params;
    username = username.replace('@', '');

    const isWallet = section === 'wallet';

    let entryList;
    let loading = false;

    if (!isWallet) {
      const groupKey = makeGroupKeyForEntries(section, `@${username}`);
      const data = entries.get(groupKey);
      if (data === undefined) {
        return null;
      }
      entryList = data.get('entries');
      loading = data.get('loading');
    } else {
      const {transactionsLoading} = this.state;
      loading = transactionsLoading;
    }

    const {transactions} = this.state;

    if (notFound) {
      return (
        <div className="wrapper">
          <NavBar{...this.props} />
          <NotFound {...this.props} />
        </div>
      )
    }

    return (
      <div className="wrapper">
        <AppView />
        <NavBar{...this.props} />
        <div className="app-content account-page">
          <div className="page-inner" id="app-content">
            <div className="left-side">
              <Profile {...this.props} username={username} account={account}/>
              <div className="clearfix"/>
            </div>
            <div className="right-side">
              <AccountMenu
                {...this.props}
                section={section}
                username={username}
              />
              {!isWallet && (
                <AccountCover
                  {...this.props}
                  account={account}
                  username={username}
                />
              )}
              {!isWallet && (
                <>
                  <div className={`entry-list ${loading ? 'loading' : ''}`}>
                    <div
                      className={`entry-list-body ${
                        global.listStyle === 'grid' ? 'grid-view' : ''
                      }`}
                    >
                      {loading && entryList.size === 0 ? (
                        <EntryListLoadingItem/>
                      ) : (
                        ''
                      )}
                      {entryList.valueSeq().map(d => (
                        <EntryListItem
                          key={`${d.author}-${d.permlink}`}
                          {...this.props}
                          entry={d}
                          asAuthor={username}
                        />
                      ))}
                      <ScrollReplace
                        {...this.props}
                        onBottom={this.bottomReached}
                      />
                    </div>
                  </div>
                  {loading && entryList.size > 0 ? <LinearProgress/> : ''}

                </>
              )}

              {isWallet && account && (
                <SectionWallet
                  {...this.props}
                  transactions={transactions}
                  username={username}
                  account={account}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Account);